import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/tmp/building-future/research_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/tmp/building-future/research_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/tmp/building-future/research_card_img3@2x.jpg';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import LinkSafe from '@/components/LinkSafe';
import { SectionCard, SectionTop } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

const card = [
  {
    id: 1,
    title: `Data for every child`,
    dec: `유니세프 D&A(Data & Analytics)팀은 전 세계 어린이와 여성에 대한 정보가 모여 있습니다. 전 세계 어린이와 여성에 대한 국가간 비교 가능한 자료 수집, 검증, 분석 및 활용을 주도합니다. 이러한 자료를 웹사이트에서 글로벌 공공재로 사용할 수 있도록 하여 어린이를 위한 지원에 근거자료로 사용할 수 있도록 지원합니다.`,
    img: CardImg1,
    more: `https://data.unicef.org/`,
  },
  {
    id: 2,
    title: `Global Insight & Policy for every child`,
    dec: `유니세프 Global Insight & Policy팀은 디지털 기술, 인적자본, 환경, 사회, 금융, 시장 총 7가지 정책 분야를 중심으로 유니세프의 싱크 탱크 역할을 합니다. 특히 어린이와 관련 문제와 담론을 효과적으로 분석하여 유니세프 프로그램에 반영할 수 있도록 준비하는 역할을 합니다.`,
    img: CardImg2,
    more: `https://www.unicef.org/globalinsight/`,
  },
  {
    id: 3,
    title: `Research for every child`,
    dec: `유니세프 이노첸티 리서치 센터는 유니세프 전담 연구 센터입니다. 유니세프가 활동하는 저소득국가와 고소득국가 내 어린이의 상황과 정책을 조사하고 분석하는 역할을 합니다. 특히 최근에는 어린이 빈곤과 형평성에 대한 다차원적 분석, 고소득 국가 어린이 웰빙, 사하라 이남 아프리카의 현금 이전 영향, 아동 폭력의 원인과 육아 지원 사업, 디지털 시대의 어린이 권리, 청소년 웰빙, 학교 환경 및 학습 경로에 대한 연구 조사가 이뤄지고 있습니다.`,
    img: CardImg3,
    more: `https://www.unicef-irc.org/`,
  },
];

const Research: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A021'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="조사와 연구"
      description="for every child, answers"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이의 권리 증진을 위해 정확한{` `}
                  <br className="small-hide" />
                  정보는 필수적입니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                유니세프는 전 세계 어린이와 여성의 모든 권리와 상황에 대한
                정확한 정보를 토대로 어린이를 옹호하는 데 선도적인 역할을
                수행합니다. 정확한 정보를 적시에 사용하면 어린이를 위해 더 나은
                세상을 만들 수 있다고 믿습니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="_icDGoHjTZs" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A021" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Research;
